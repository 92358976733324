<template>
  <div>
    <app-header @getHeight="getHeight"></app-header>
    <div style="background-color: #fff">
      <div class="max_width min_width">
        <div
          class="flex align_center padtb_20"
          :style="{ 'margin-top': offsetHeight + 'px' }"
        >
          <div class="f838 size_14">当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <!-- <el-breadcrumb-item class="d8f">政策法规</el-breadcrumb-item> -->
            <el-breadcrumb-item class="" style="color: #ff6618"
              >供方市场</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <div style="background-color: #fff">
      <div class="max_width min_width">
        <section class="bgfff">
          <div
            style="
              width: 1480px;
              padding: 30px;
              background-color: rgb(255, 221, 204);

              border-radius: 5px;
            "
          >
            <div>
              <div class="flex align_start" style="padding: 10px 0px">
                <div class="fnf size_15 weight marr_30 text_nowrap">
                  行业类型：
                </div>
                <div class="text_line1" style="width: calc(100% - 75px)">
                  <span
                    class="fnf weight size_15 marr_30 laws_font"
                    :class="{ laws_tag: patterns == '' }"
                    @click="setTag('patterns')"
                    >全部</span
                  >
                  <span
                    class="fnf size_15 marr_30 laws_font"
                    v-for="(item, index) in pattern"
                    :key="index"
                    :class="{ laws_tag: patterns == item.id }"
                    @click="setTag('patterns', item.id)"
                    >{{ item.name }}</span
                  >
                </div>
              </div>
            </div>
            <!-- 搜索框 -->

            <div
              class="flex align_center"
              style="justify-content: space-between"
            >
              <div class="flex align_center mart_20">
                <div class="fnf size_15 weight text_nowrap">关键词搜索：</div>
                <div class="width_50 sear_search">
                  <el-input
                    placeholder="请输入关键词"
                    style="width: 500px"
                    v-model="keyword"
                    @keyup.enter="search"
                  >
                    <i
                      slot="suffix"
                      class="el-icon-search"
                      @click="search()"
                    ></i>
                  </el-input>
                </div>
              </div>

              <div
                style="
                  width: 130px;
                  height: 40px;
                  background: #ff6417;
                  border-radius: 6px;
                  line-height: 40px;
                  text-align: center;
                  color: #ffffff;
                "
                @click="authentication"
              >
                发布需求
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div style="background-color: #fff; padding: 30px 0px">
      <div class="max_width min_width">
        <section class="bgfff">
          <div class="flex" style="flex-wrap: wrap;justify-content: space-between;">
            <div
              @click="look(item.id)"
              v-for="item in selectorder"
              :key="item.id"
              style="
                width: 252px;
                height: 256px;
                background: #ffffff;
                border: 1px solid #f2f2f2;
                margin-bottom: 20px;
                box-shadow: 0px 2px 8px 0px rgba(0, 132, 255, 0.15);
              "
            >
              <div style="padding-bottom: 20px">
                <img
                  :src="item.supply_img"
                  style="width: 250px; height: 157px"
                  alt=""
                />
              </div>
              <div style="padding: 0px 20px 20px 20px">
                {{ item.product_name }}
              </div>
              <div style="padding: 0px 20px 20px 20px">
                {{ item.enterprise_name.substr(0, 14) + "..." }}
              </div>
            </div>
          </div>

          <!-- <div
              class="flex jus_content"
              style="align-items: center; border-bottom: 1px solid #e8e8e8"
              v-for="(item, index) in selectorder"
              :key="index"
            >
              <div style="flex: 1">
                <div class="size_18 servehove" style="padding: 10px 0px">
                  {{ item.product_name }}
                </div>
                <div class="flex size_14" style="margin-bottom: 20px">
                  <div>行业类型：{{ item.industry_name }}</div>
                  <div class="marl_30 size_14" style="color: #808080">
                    发布时间：{{ item.create_time }}
                  </div>
                </div>
              </div>
              <div
                style="
                  width: 130px;
                  height: 40px;
                  border-radius: 6px;
                  line-height: 40px;
                  text-align: center;
                  color: #ff6417;
                  background: #fff3eb;
                "
                @click="look(item.id)"
              >
                查看详情
              </div>
            </div> -->
        </section>
      </div>
    </div>
    <div>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="paginations.page_index"
        :page-sizes="paginations.page_sizes"
        :page-size="paginations.page_size"
        :layout="paginations.layout"
        :total="paginations.total"
      >
      </el-pagination>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";

export default {
  data() {
    return {
      offsetHeight: 0,
      keyword: "",
      num: "",
      allselectorder: [],
      category: "", // 栏目类别
      selectorder: [],
      TableData: [],
      pattern: [
        { id: 1, name: "盐湖化工" },
        { id: 2, name: "新能源" },
        { id: 3, name: "新材料" },
        { id: 4, name: "冶金产业" },
        { id: 5, name: "绿色建材" },
        { id: 6, name: "装备制造" },
        { id: 7, name: "信息技术" },
        { id: 8, name: "节能环保" },
        { id: 9, name: "高原特色" },
        { id: 10, name: "其他" },
      ],
      patterns: "", //服务模式
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
    };
  },

  components: {
    "app-header": Header,
    "app-footer": Footer,
  },
  created() {
    // this.selectlist();
    this.marketlist();
  },
  methods: {
    mechanism_status(item) {
      let data = {
        token: localStorage.eleToken,
      };
      this.$get("/mechanism_status", data).then((res) => {
        console.log(res, "res");
        if (res.status === 1) {
          this.gorelease(item);
        }
      });
    },

    // 认证
    authentication(item) {
      const index = localStorage.logintype;
      console.log(index, "index");
      if (index == 2) {
        this.mechanism_status();
      } else if (index == 3) {
        this.$message({
          type: "warning",
          message: "该产品只限企业用户和服务机构申请",
        });
      } else {
        let data = {
          token: localStorage.eleToken,
        };
        console.log(index, "index");
        console.log(data, "data");
        this.$post("/select_enterprise_status", data).then((res) => {
          console.log(res, "res");

          if (res.result === 2) {
            this.gorelease(item);
          } else if (res.result === 0 || res.result === 3) {
            // location.href = "http://sso.zhenchangkj.com:9000/realname/index";
            location.href = "https://sso.smeqh.cn/realname/index";
          } else if (res.result === 1) {
            this.$message({
              type: "info",
              message: "您的企业正在认领中，审核通过之后才能进行下一步操作",
            });
          }
        });
      }
    },

    look(item) {
      this.$router.push({ path: "/feedmessage", query: { id: item } });
    },
    // 获取供方列表
    marketlist(item) {
      let data = {
        token: localStorage.eleToken,
        keyword: this.keyword,
        industry_id: this.patterns,
        page: item ? item : this.paginations.page_index,
        limit: this.paginations.page_size,
      };
      console.log(data, "data");
      this.$post("supply_lst_index", data).then((res) => {
        // this.TableData = res.result;
        this.allselectorder = res.result;
        if (res.count === "") {
          this.paginations.total = 0;
        } else {
          this.paginations.total = res.count;
        }

        //设置默认的分页数据
        if (this.allselectorder.length != 0) {
          this.selectorder = this.allselectorder.filter((item, index) => {
            return index < this.paginations.page_size;
          });
        } else {
          this.selectorder = [];
        }
        console.log(this.allselectorder, "this.allselectorder");
      });
    },

    //去发布产品页面
    gorelease() {
      this.$router.push("/releasefeed");
    },
    setTag(name, id = "") {
      this[name] = id;
      this.paginations.page_index = 1;
      console.log("789");
      this.policyList = [];
      this.marketlist();
      //   this.selectlist();
    },
    search() {
      console.log("789456");
      this.marketlist();
    },

    // selectlist() {
    //   let data = {
    //     keyword: this.keyword,
    //     pro_cate_id: this.category,
    //     mode: this.patterns,
    //     page: this.paginations.page_index,
    //     limit: this.paginations.page_size,
    //   };
    //   this.$post("service_product_index", data).then((res) => {
    //     console.log(res, "res");
    //     this.allselectorder = res.result;

    //     this.setPaginations();
    //   });
    // },

    getHeight(data) {
      console.log(data, "data");
      this.offsetHeight = data;
    },

    // 分页
    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.page_size = page_size;
      this.marketlist();
      this.selectorder = this.allselectorder.filter((item, index) => {
        return index < page_size;
      });
    },

    handleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;

      this.marketlist(page);
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.allselectorder[i]) {
          console.log(this.allselectorder[i],'this.allselectorder[i]')
          tables.push(this.allselectorder[i]);
        }
        this.selectorder = tables;
      }
    },
  },
};
</script>
<style scoped>
.sear_search {
  border-radius: 6px;
  margin-left: 12px;
}

.el-input__inner {
  background-color: #f5f5f5 !important;
  border: none !important;
  border-radius: 25px !important;
}

.sear_search .el-icon-search {
  right: 10px;
  color: #ff7129 !important;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  top: 10px;
  position: relative;
}

.sear_search .el-input__clear {
  height: 100% !important;
}

.laws_tag {
  padding: 3px 12px;

  color: #ff7129;
  background: #ffffff;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
}

::v-deep.el-pagination {
  text-align: center;
  padding: 20px 0px;
  background-color: #fff;
}

.productbox {
  width: 272px;
  height: 294px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  margin: 0px 40px 40px 0px;
}

.productbox:hover {
  box-shadow: 0px 2px 8px 0px rgba(0, 132, 255, 0.15);
}

.servehove:hover {
  color: #ff7129;
}
</style>
